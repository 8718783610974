import styled from 'styled-components';

export const Body = styled.div`
  padding: 75px;
  text-align: center;
  width: 820px;
  margin: 0 auto;
  @media (max-width: 870px) {
    padding: 35px;
    width: 100%;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 20px 0;
  padding: 0;
  color: #484848;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  @media (max-width: 870px) {
    font-size: 16px;
  }
`;

export const ImageStyle = styled.img`
  display: block;
  width: 100%;
  max-width: 320px;
  height: auto;
  margin: 0 auto 50px auto;
`;

export const LinkStyle = styled.a`
  width: 70%;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 130%;
  color: #9a9a9a;
`;

export const Header1 = styled.h1`
  position: relative;
  padding: 0;
  margin: 0 0 30px 0;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 50px;
  line-height: 50px;
  color: #0e4c88;
`;
